<template>
	<div class="muu-coupon" v-if="couponList.length>0">
		<div class="muu-coupon-list">
			<div class="item" v-for="(item,index) in couponList" :key="index" @click="handleReceiveCoupon(item.coupon_id)">
				<div class="item-main">
					<div class="price">￥<span>{{parseInt(item.money)}}</span>{{$t('mobile.shop.text13')}}</div>
					<div class="text">{{$t('mobile.shop.text14')}}{{parseInt(item.quota)}}{{$t('mobile.shop.text15')}}</div>
					<div class="text">{{item.use_begin_time | parseTime('{y}.{m}.{d}')}}-{{item.use_end_time | parseTime('{y}.{m}.{d}')}}</div>
				</div>
				<div class="item-box">{{$t('mobile.shop.text16')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCoupons, couponReceive } from '@/api/mobile/store';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				couponList: []
			}
		},
		props: ['value'],
		created() {
			this.getCouponList();
		},
		methods: {
			getCouponList() {
				let params = {
					coupon_id: this.value,
					store_id: this.$route.params.id
				}
				getCoupons(params).then(response => {
					this.couponList = response.data.list
				})
			},
			handleReceiveCoupon(id){
				const hasToken = getToken()
				if(!hasToken) {
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning'
					});
					window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
				}
				
				this.$confirm(this.$t('mobile.confirm.drawCoupon'),this.$t('mobile.confirm.title'),{
					confirmButtonText: this.$t('mobile.actions.draw'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					type: 'warning',
					customClass: 'wap-message-confirm',
				}).then(()=>{
					couponReceive({coupon_id: id}).then(response => {
						this.$message({
							message: this.$t('mobile.successMsg.draw'),
							customClass: 'wap-message',
							type: 'success'
						});
					})
				}).catch(()=>{
					this.$message({
						message: this.$t('mobile.cancelMsg.draw'),
						customClass: 'wap-message',
						type: 'info'
					});
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.muu-coupon {
		padding: 0.16rem;
		height: 2.56rem;
		background-color: #FFFFFF;
		border-radius: 0.32rem;
		overflow: hidden;
		.muu-coupon-list {
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			height: 2.752rem;
			padding-bottom: 15px;
		
			.item {
				flex-shrink: 0;
				width: 6.144rem;
				padding: 0.16rem;
				height: 2.56rem;
				display: flex;
				box-sizing: border-box;
				border-radius: 0.256rem;
				background-color: #fff1ee;
				margin-right: 0.192rem;
		
				.item-main {
					flex: 1;
					padding: 0 0.32rem;
					color: #ff1717;
		
					.price {
						height: 1.088rem;
						line-height: 1.088rem;
						font-size: 0.384rem;
		
						span {
							font-size: 0.832rem;
							margin-left: 0.064rem;
							margin-right: 0.128rem;
						}
					}
		
					.text {
						font-size: 0.352rem;
						line-height: 1em;
						margin-top: 0.192rem;
						white-space: nowrap;
					}
				}
		
				.item-box {
					width: 1.024rem;
					padding: 0 0.32rem;
					font-size: 0.384rem;
					box-sizing: border-box;
					text-align: center;
					color: #ff1717;
					line-height: 0.736rem;
					border-left: 1px dashed #ff1717;
				}
			}
		
			.item:last-child {
				margin-right: 0;
			}
		}
	}
</style>
