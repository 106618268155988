<template>
	<div class="mstore-ranking">
		<div class="ranking-head">
			<div class="item" :class="activeName==='sales'?'active':''" @click="getGoodsList('sales')">{{$t('mobile.shop.rank1')}}</div>
			<div class="item" :class="activeName==='collect'?'active':''" @click="getGoodsList('collect')">{{$t('mobile.shop.rank2')}}</div>
			<div class="item" :class="activeName==='news'?'active':''" @click="getGoodsList('news')">{{$t('mobile.shop.rank3')}}</div>
		</div>
		<div class="ranking-list">
			<router-link :to="{ name: 'mGoodsInfo', params: { id: item.goods_id }}" class="item" v-for="(item,index) in goodsList" :key="index">
				<img :src="item.default_image" @error="imageLoadError" class="pic">
				<div class="item-main">
					<div class="item-box">
						<div class="name">{{item.goods_name}}</div>
						<div class="desc" v-if="activeName==='news'"><i class="icon-hot"></i><span>{{item.want_buy_num}}</span>{{$t('mobile.shop.rank4')}}</div>
						<div class="desc" v-else><i class="icon-hot"></i><span>{{item.interest_num}}</span>{{$t('mobile.shop.rank5')}}</div>
					</div>
					<div class="text">
						<div class="price">￥<span>{{item.price}}</span></div>
						<div class="number" v-if="activeName==='sales'">{{item.sales}}{{$t('mobile.tags.sales')}}</div>
						<div class="number" v-else>{{item.collects}}{{$t('mobile.tags.collects')}}</div>
					</div>
				</div>
				<img class="item-icon" v-if="index == 0" src="@/assets/images/seller/decorate-steps-136-160-1.png" alt="">
				<img class="item-icon" v-if="index == 1" src="@/assets/images/seller/decorate-steps-136-160-2.png" alt="">
				<img class="item-icon" v-if="index == 2" src="@/assets/images/seller/decorate-steps-136-160-3.png" alt="">
			</router-link>
			<div class="ranking-more"><span>{{$t('mobile.actions.viewAll')}}</span></div>
		</div>
	</div>
</template>
<script>
	import { getViewGoods } from '@/api/mobile/store';
	export default {
		data() {
			return {
				activeName: 'sales',
				goodsList: [],
			}
		},
		mounted() {
			this.getGoodsList('sales');
		},
		methods: {
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
			},
			getGoodsList(type){
				this.activeName = type;
				let params = {
					d_type: type,
					goods_id: '',
					store_id: this.$route.params.id,
					page_index: 0,
					page_size: 3
				}
				getViewGoods(params).then(response => {
					this.goodsList = response.data.list;
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.mstore-ranking {
		.ranking-head {
			width: 10.848rem;
			box-sizing: border-box;
			display: flex;
			padding: 0.096rem;
			margin: 0 auto;
			border-radius: 1.408rem;
			background-color: #FFFFFF;
			margin-bottom: 0.32rem;
			.item {
				flex: 1;
				font-size: 0.512rem;
				height: 1.216rem;
				line-height: 1.216rem;
				text-align: center;
				border-radius: 1.216rem;
			}
			.item.active {
				color: #ff5000;
				background-color: #fff1eb;
			}
		}
		.ranking-list {
			border-radius: 20px;
			overflow: hidden;
			padding: 0.384rem;
			background-color: #FFFFFF;
			.item {
				display: flex;
				position: relative;
				margin-bottom: 0.384rem;
				.pic {
					width: 3.84rem;
					height: 3.84rem;
					border-radius: 0.256rem;
					object-fit: cover;
				}
				.item-main {
					width: 0;
					flex: 1;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					justify-content: space-between;
					margin-left: 0.384rem;
					padding: 0.224rem 0;
					.item-box {
						width: 100%;
					}
					.name {
						color: #333333;
						height: 0.544rem;
						line-height: 0.544rem;
						font-size: 0.448rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.text {
						height: 0.768rem;
						display: flex;
						align-items: flex-end;
					}
					.price {
						color: #ff5000;
						font-size: 0.384rem;
						margin-right: 0.16rem;
						span {
							font-size: 0.576rem;
						}
					}
					.number {
						color: #999999;
						font-size: 0.384rem;
					}
					.desc {
						display: flex;
						align-items: center;
						font-size: 0.416rem;
						margin-top: 0.32rem;
						span {
							color: #FF5500;
							margin: 0 0.128rem;
						}
					}
					.icon-hot {
						width: 0.416rem;
						height: 0.416rem;
						background-image: url('~@/assets/images/seller/decorate-steps-52-52.png');
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
				}
				.item-icon {
					left: 0.224rem;
					top: -0.16rem;
					width: 1.088rem;
					position: absolute;
				}
			}
		}
		.ranking-more {
			font-size: 0.448rem;
			color: #999999;
			text-align: center;
			line-height: 0.512rem;
		}
	}
</style>