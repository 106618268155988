<template>
	<div class="mstore-recommend">
		<div class="recommend-list" :class="pageStyle==='1'?'theme-one':pageStyle==='3'?'theme-three':''">
			<router-link class="item" v-for="(item,index) in goodsList" :key="index" :to="{ name: 'mGoodsInfo', params: { id: item.goods_id }}">
				<img :src="item.default_image" @error="imageLoadError" class="pic">
				<div class="item-main">
					<div class="name">{{item.goods_name}}</div>
					<div class="text">
						<div class="price">￥<span>{{item.price}}</span></div>
						<div class="number" v-if="pageStyle!=='3'">{{item.sales}}{{$t('mobile.tags.sales')}}</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script>
	import { getViewGoods } from '@/api/mobile/store';
	export default {
		data() {
			return {
				goodsList: [],
			}
		},
		props: ['pageIds','pageStyle'],
		mounted() {
			this.getGoodsList();
		},
		methods: {
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getGoodsList() {
				let params = {
					goods_id: this.pageIds.split(','),
					store_id: this.$route.params.id
				}
				getViewGoods(params).then(response => {
					this.goodsList = response.data.list;
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.recommend-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -0.32rem;
		justify-content: space-between;

		.item {
			color: #333333;
			width: 5.52rem;
			overflow: hidden;
			background-color: #FFFFFF;
			border-radius: 0.32rem;
			margin-bottom: 0.32rem;

			.pic {
				width: 100%;
				height: 5.52rem;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.item-main {
				padding: 0.32rem;

				.name {
					font-size: 0.448rem;
					height: 0.544rem;
					line-height: 0.544rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-bottom: 0.16rem;
				}

				.text {
					display: flex;
					line-height: 0.64rem;
					align-items: center;
				}

				.price {
					color: #ff5000;
					font-size: 0.384rem;

					span {
						font-size: 0.576rem;
					}
				}

				.number {
					color: #999999;
					font-size: 0.384rem;
					margin-left: 0.096rem;
				}
			}
		}
	}
	.theme-three {
		.item {
			width: 3.552rem;
			border-radius: 0.256rem;
			.pic {
				height: 3.552rem;
			}
			.item-main {
				padding: 0.192rem;
				.name {
					font-size: 0.416rem;
				}
				.price {
					font-size: 0.352rem;
					span {
						font-size: 0.448rem;
					}
				}
			}
		}
	}
	.theme-one {
		.item {
			width: 100%;
			display: flex;
			.pic {
				width: 4.48rem;
				height: 4.48rem;
			}
			.item-main {
				flex: 1;
				width: 0;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				.name {
					height: 1.088rem;
					line-height: 0.544rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					white-space: normal;
				}
			}
		}
	}
</style>