<template>
	<div class="mhome">
		<div class="mhome-wrap" @scroll="handleScroll">
			<div class="mstore-head" :class="{ mfixed: scrollFlag }">
				<div class="head-main" v-show="!scrollFlag">
					<router-link class="user-info" :to="{name:'mStoreImpression',params:{id:$route.params.id}}">
						<div class="name">{{storeInfo.store_name}}</div>
						<div class="text">{{$t('mobile.tags.fans')+':'}}{{storeInfo.count_fans || 0}}</div>
					</router-link>
					<div class="bar">
						<el-button type="info" size="mini" @click="handleUnFollow" v-if="storeInfo.is_follow==1">{{$t('mobile.actions.unfollow')}}</el-button>
						<el-button type="danger" size="mini" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</el-button>
					</div>
				</div>
				<div class="head-nav">
					<ul>
						<li class="active"><router-link :to="{name: 'mStoreInfo',params:{id:$route.params.id}}" class="text">{{$t('mobile.tags.home')}}</router-link></li>
						<li><router-link :to="{name: 'mStoreGoods',params:{id:$route.params.id}}" class="text">{{$t('mobile.tags.baby')}}</router-link></li>
					</ul>
				</div>
			</div>
			<div v-show="scrollFlag" style="height: 1.984rem;"></div>
			<div class="mstore-main">
				<template v-for="(item,index) in typesetList">
					<div class="mstore-section mstore-swiper" :key="index"
						v-if="item.belong_container == 'carousel' && item.page_module_id > 0">
						<swiper ref="mySwiper" :options="swiperOptions">
							<swiper-slide v-for="(thumb,index2) in item.page_module_content.attr_thumb" :key="index2">
								<a :href="thumb.link_url ? thumb.link_url : 'javascript:void(0);'"><img :src="thumb.img_url"
										alt="" width="100%"></a>
							</swiper-slide>
							<div class="swiper-pagination" slot="pagination"></div>
						</swiper>
					</div>
					<a :href="item.page_module_content.attr_link ? item.page_module_content.attr_link : 'javascript:void(0);'"
						class="mstore-section mstore-poster" :key="index"
						v-else-if="item.belong_container == 'poster-single' && item.page_module_id > 0">
						<img :src="item.page_module_content.attr_data" alt="" width="100%">
					</a>
					<a :href="item.page_module_content.attr_link ? item.page_module_content.attr_link : 'javascript:void(0);'"
						class="mstore-section mstore-notice" :key="index"
						v-else-if="item.belong_container == 'text' && item.page_module_id > 0">
						<img class="notice-icon" src="@/assets/images/seller/decorate-steps-136-136.png" alt="">
						<div class="notice-text">{{item.content}}</div>
						<i class="notice-next el-icon-arrow-right"></i>
					</a>
					<a :href="item.page_module_content.attr_link ? item.page_module_content.attr_link : 'javascript:void(0);'"
						class="mstore-section mstore-poster" :key="index"
						v-else-if="item.belong_container == 'video-single' && item.page_module_id > 0">
						<video :src="item.page_module_content.attr_data" controls="controls" width="100%"></video>
					</a>
					<div class="mstore-section" :key="index"
						v-else-if="item.belong_container == 'goods-ranking' && item.page_module_id > 0">
						<store-goods-ranking></store-goods-ranking>
					</div>
					<div class="mstore-section" :key="index"
						v-else-if="item.belong_container == 'goods-recommend' && item.page_module_id > 0">
						<a :href="item.page_module_content.attr_link ? item.page_module_content.attr_link : 'javascript:void(0);'" class="section-banner" v-if="item.page_module_content.attr_banner">
							<img :src="item.page_module_content.attr_banner" alt="" width="100%">
						</a>
						<store-goods-recommend :page-ids="item.content" :page-style="item.style_type"></store-goods-recommend>
					</div>
					<div class="mstore-section" :key="index"
						v-else-if="item.belong_container == 'coupon-shop' && item.page_module_id > 0">
						<store-coupon v-model="item.content"></store-coupon>
					</div>
				</template>
				<div class="mstore-section" v-if="!typesetList.length>0">
					<store-goods-ranking></store-goods-ranking>
				</div>
				<div class="mstore-title"><img src="@/assets/images/seller/decorate-steps-782-70.png" alt=""></div>
				<div class="mstore-goods">
					<router-link v-for="(item,index) in goodsList" :key="index"
						:to="{ name: 'mGoodsInfo', params: { id: item.goods_id }}" class="item">
						<div class="pic"><img :src="item.default_image" @error="imageLoadErrorGoods"></div>
						<div class="item-main">
							<div class="name">{{item.goods_name}}</div>
							<div class="text">
								<div class="price">￥<span>{{item.price}}</span></div>
								<div class="number">{{item.sales}}{{$t('mobile.tags.sales')}}</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import storeGoodsRecommend from '@/components/mobile/storeGoodsRecom.vue';
	import storeGoodsRanking from '@/components/mobile/storeGoodsRanking.vue';
	import storeCoupon from '@/components/mobile/storeCoupon.vue';
	import { getToken } from '@/utils/auth';
	import { fetchShopInfo, fetchShopView, getViewGoods, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	export default {
		components:{
			storeGoodsRecommend,
			storeGoodsRanking,
			storeCoupon,
		},
		data() {
			return {
				scrollFlag: false,
				storeInfo: {},
				typesetList: [],
				swiperOptions: {
					loop: true,
					autoplay: true,
					pagination: {
						el: '.swiper-pagination',
					},
				},
				goodsList: [],
				pageTotal: 0,
				pageCurr: 1,
				pageSize: 10,
				pageFlag: true,
			}
		},
		mounted() {
			this.getStoreInfo()
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			async getStoreInfo() {
				try {
					const params = {
						store_id: this.$route.params.id
					}
					const info = await fetchShopInfo(params)
					this.storeInfo = info.data

					await browseStore({...params, from: 4 })

					const preview = await fetchShopView(params)
					this.typesetList = preview.data ? preview.data.content : []
					this.getGoodsList()
				} catch (error) {
					this.$router.go(-1)
				}
			},
			getGoodsList() {
				let loading = this.$loading();
				let param = {
					d_type: 'more',
					store_id: this.$route.params.id,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getViewGoods(param).then(response => {
					loading.close();
					if (response.data.list.length > 0) {
						this.goodsList = this.goodsList.concat(response.data.list);
					}
					if(response.data.list.length<this.pageSize) {
						this.pageFlag = false;
					}
					this.pageTotal = parseInt(response.data.count);
				}).catch(() => {
					loading.close();
				})
			},
			handleScroll($event) {
				var scrollTop = $event.target.scrollTop;
				var _height = document.querySelector(".mstore-head").offsetHeight;
				if (scrollTop > _height) {
					this.scrollFlag = true;
				} else {
					this.scrollFlag = false;
				}
				var offsetHeight = $event.target.offsetHeight;
				var scrollHeight = $event.target.scrollHeight;
				if(offsetHeight+scrollTop>=scrollHeight && this.pageFlag){
					this.pageCurr++;
					this.getGoodsList();
				}
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.getStoreInfo();
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					this.getStoreInfo();
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.mstore-head {
		background-color: #f4f4f4;

		.head-main {
			display: flex;
			justify-content: space-between;
			padding: 0.32rem 0.32rem 0;

			.user-info {
				.name {
					width: 6.4rem;
					color: #333333;
					font-size: 0.544rem;
					height: 0.928rem;
					line-height: 0.928rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.text {
					color: #999999;
					font-size: 0.416rem;
					height: 0.512rem;
					line-height: 0.512rem;
				}
			}

			.bar {
				::v-deep .el-button {
					width: 1.984rem;
					height: 0.928rem;
					padding: 0.256rem 0.288rem;
					border-radius: 0.48rem;
					font-size: 0.352rem;
					background-color: transparent;
				}

				::v-deep .el-button--danger {
					color: #ff5000;
					border-color: #ff5000;
					background-color: transparent;
				}
				::v-deep .el-button--danger:focus,
				.el-button--danger:hover {
					color: #ff5000;
					border-color: #ff5000;
					background-color: transparent;
				}
				::v-deep .el-button--info {
					color: #606266;
					border-color: #DCDFE6;
					background-color: transparent;
				}
				::v-deep .el-button--info:focus,
				.el-button--info:hover {
					color: #606266;
					border-color: #DCDFE6;
					background-color: transparent;
				}
			}
		}

		.head-nav {
			display: flex;
			height: 1.664rem;
			align-items: center;
			padding: 0 0.32rem;

			li {
				display: inline-block;
				margin-right: 0.768rem;

				.text {
					color: #333333;
					display: block;
					height: 0.704rem;
					font-size: 0.48rem;
					line-height: 0.704rem;
					font-weight: 500;
					border-bottom: 0.064rem solid transparent;
				}
			}

			li.active {
				.text {
					color: #ff5000;
					border-bottom-color: #ff5000;
				}
			}
		}
	}

	.mstore-main {
		padding: 0 0.32rem;

		.mstore-section {
			display: block;
			margin-bottom: 0.32rem;
			.section-banner {
				border-radius: 0.32rem;
				margin-bottom: 0.32rem;
				img {
					width: 100%;
				}
			}
		}

		.mstore-swiper {
			::v-deep .swiper-container {
				position: relative;
				overflow: hidden;
				border-radius: 0.32rem;

				.swiper-slide {
					a {
						display: block;
					}

					img {
						object-fit: cover;
					}
				}

				.swiper-pagination {
					height: 0;
					bottom: 0.64rem;

					.swiper-pagination-bullet {
						width: 0.32rem;
						height: 0.128rem;
						border-radius: 0;
					}

					.swiper-pagination-bullet-active {
						background-color: #ff1717;
					}
				}
			}
		}

		.mstore-poster {
			overflow: hidden;
			border-radius: 0.32rem;
		}

		.mstore-notice {
			display: flex;
			align-items: center;
			padding: 0.256rem 0.32rem;
			border-radius: 0.32rem;
			background-color: #FFFFFF;

			.notice-icon {
				width: 1.088rem;
				height: 1.088rem;
				margin-right: 0.384rem;
			}

			.notice-text {
				flex: 1;
				width: 0;
				color: #000000;
				font-size: 0.512rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.notice-next {
				margin-left: 0.384rem;
				font-size: 0.512rem;
				font-weight: 500;
			}
		}

		.mstore-title {
			height: 0.576rem;
			text-align: center;
			padding: 0.32rem 0;
			margin-bottom: 0.256rem;

			img {
				height: 100%;
			}
		}

		.mstore-goods {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.item {
				color: #333333;
				width: 5.52rem;
				overflow: hidden;
				background-color: #FFFFFF;
				border-radius: 0.32rem;
				margin-bottom: 0.32rem;

				.pic {
					width: 100%;
					height: 5.52rem;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.item-main {
					padding: 0.32rem;

					.name {
						font-size: 0.448rem;
						height: 0.544rem;
						line-height: 0.544rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 0.16rem;
					}

					.text {
						display: flex;
						line-height: 0.64rem;
						align-items: center;
					}

					.price {
						color: #ff5000;
						font-size: 0.384rem;

						span {
							font-size: 0.576rem;
						}
					}

					.number {
						color: #999999;
						font-size: 0.384rem;
						margin-left: 0.096rem;
					}
				}
			}
		}
	}
	.mfixed {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		box-sizing: border-box;
		position: absolute;
	}
</style>
